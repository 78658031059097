<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="sass">
@import "@/assets/scss/style"
</style>

<script>
  export default {};
</script>