import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '@/views/Main'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter)

const routes = [
    {
        path: '/:relation?',
        name: 'main',
        component: Main,
    },
]

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes
})

export default router