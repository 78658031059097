<template>
  <b-container>
    <b-row>
      <b-col class="text-center">
        <h1>Laravel's Relationship Cheatsheet</h1>
      </b-col>
    </b-row>

    <b-row class="mt-5 justify-content-center">
      <b-col md="24" class="text-center">
        <b-form-group label="Relationship">
          <b-select
            v-model="selectedRelationship"
            :options="relationships"
            @change="updateUrl(selectedRelationship)"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col md="16" class="text-center">
        <b-form-group label="Current model">
          <b-input v-model="models.current" />
        </b-form-group>
      </b-col>
      <b-col
        md="16"
        v-if="
          selectedRelationship === 'has-many-through' ||
          selectedRelationship === 'has-one-through'
        "
        class="text-center"
      >
        <b-form-group label="Intermediate model">
          <b-input v-model="models.intermediate" />
        </b-form-group>
      </b-col>
      <b-col md="16" class="text-center">
        <b-form-group label="Target model">
          <b-input v-model="models.target" />
        </b-form-group>
      </b-col>
    </b-row>

    <has-many-through
      v-if="selectedRelationship === 'has-many-through'"
      :models="models"
      :currentTable="currentTable"
      :intermediateTable="intermediateTable"
      :targetTable="targetTable"
      :targetIntermediateForeignKey="targetIntermediateForeignKey"
      :intermediateCurrentForeignKey="intermediateCurrentForeignKey"
    />

    <has-one-through
      v-if="selectedRelationship === 'has-one-through'"
      :models="models"
      :currentTable="currentTable"
      :intermediateTable="intermediateTable"
      :targetTable="targetTable"
      :targetIntermediateForeignKey="targetIntermediateForeignKey"
      :intermediateCurrentForeignKey="intermediateCurrentForeignKey"
    />

    <belongs-to-many
      v-if="selectedRelationship === 'belongs-to-many'"
      :models="models"
      :currentTable="currentTable"
      :intermediateTable="intermediateTable"
      :targetTable="targetTable"
      :currentTargetForeignKey="currentTargetForeignKey"
      :targetCurrentForeignKey="targetCurrentForeignKey"
    />

    <has-one
      v-if="selectedRelationship === 'has-one'"
      :models="models"
      :currentTable="currentTable"
      :targetTable="targetTable"
      :targetCurrentForeignKey="targetCurrentForeignKey"
    />

    <has-many
      v-if="selectedRelationship === 'has-many'"
      :models="models"
      :currentTable="currentTable"
      :targetTable="targetTable"
      :targetCurrentForeignKey="targetCurrentForeignKey"
    />

    <belongs-to
      v-if="selectedRelationship === 'belongs-to'"
      :models="models"
      :currentTable="currentTable"
      :targetTable="targetTable"
      :currentTargetForeignKey="currentTargetForeignKey"
    />

    <b-row class="footer mt-5 text-center">
      <b-col>
        With much
        <span class="text-primary"><i class="far fa-heart" /></span> by Mikkol
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import HasManyThrough from "@/components/HasManyThrough";
  import HasOneThrough from "@/components/HasOneThrough";
  import HasOne from "@/components/HasOne";
  import HasMany from "@/components/HasMany";
  import BelongsTo from "@/components/BelongsTo";
  import BelongsToMany from "@/components/BelongsToMany";

  export default {
    components: {
      HasManyThrough,
      HasOneThrough,
      HasOne,
      HasMany,
      BelongsTo,
      BelongsToMany,
    },
    data() {
      return {
        relationships: [
          {
            text: "HasOne",
            value: "has-one",
          },
          {
            text: "HasMany",
            value: "has-many",
          },
          {
            text: "BelongsTo",
            value: "belongs-to",
          },
          {
            text: "HasOneThrough",
            value: "has-one-through",
          },
          {
            text: "HasManyThrough",
            value: "has-many-through",
          },
          {
            text: "BelongsToMany",
            value: "belongs-to-many",
          },
        ],

        selectedRelationship: "has-one",

        models: {
          current: "Customer",
          intermediate: "Order",
          target: "Invoice",
        },
      };
    },

    computed: {
      currentTable() {
        return this.models.current
          ? this.$pluralize(this.models.current, 2).toLowerCase()
          : "";
      },

      intermediateTable() {
        return this.models.intermediate
          ? this.$pluralize(this.models.intermediate, 2).toLowerCase()
          : "";
      },

      targetTable() {
        return this.models.target
          ? this.$pluralize(this.models.target, 2).toLowerCase()
          : "";
      },

      currentTargetForeignKey() {
        return `${this.models.target.toLowerCase()}_id`;
      },

      currentIntermediateForeignKey() {
        return `${this.models.intermediate.toLowerCase()}_id`;
      },

      targetIntermediateForeignKey() {
        return `${this.models.intermediate.toLowerCase()}_id`;
      },

      targetCurrentForeignKey() {
        return `${this.models.current.toLowerCase()}_id`;
      },

      intermediateCurrentForeignKey() {
        return `${this.models.current.toLowerCase()}_id`;
      },

      intermediateTargetForeignKey() {
        return `${this.models.target.toLowerCase()}_id`;
      },
    },

    methods: {
      updateUrl(relation) {
        this.$router.push({
          name: "main",
          params: { relation: relation },
        });

        this.selectedRelationship = relation;
      },
    },

    mounted() {
      this.selectedRelationship =
        typeof this.$route.params.relation !== "undefined"
          ? this.$route.params.relation
          : "has-one";
    },
  };
</script>

<style>
</style>