<template>
  <div>
    <hr class="my-5" />

    <b-row class="mt-5">
      <b-col class="text-center">
        <h2>Explanation</h2>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <div class="code text">
          <pre
            contenteditable
          >- The current model <span class="text-current">{{ models.current }}</span> has a relation to <span class="text-target">{{ models.target }}</span> via <span class="text-intermediate">{{ models.intermediate }}</span>
- The <span class="text-intermediate">intermediate model</span> is linked to the <span class="text-current">current model</span> via <span class="text-intermediate">{{ intermediateTable }}.{{ intermediateCurrentForeignKey }}</span>
- The <span class="text-target">target model</span> is linked to the <span class="text-intermediate">intermediate model</span> via <span class="text-target">{{ targetTable }}.{{ targetIntermediateForeignKey }}</span>
- <span class="text-intermediate">{{ intermediateTable }}.{{ intermediateCurrentForeignKey }}</span> maps to <span class="text-current">{{ currentTable }}.id</span>
- <span class="text-target">{{ targetTable }}.{{ targetIntermediateForeignKey }}</span> maps to <span class="text-intermediate">{{ intermediateTable }}.id</span></pre>
        </div>
      </b-col>
    </b-row>

    <hr class="my-5" />

    <b-row>
      <b-col class="text-center">
        <h2>Code</h2>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <div class="code">
          <pre
            contenteditable
          ><span class="keyword">class</span> <span class="text-current">{{ models.current }}</span> <span class="keyword">extends</span> <span class="identifier-class">Model</span>
{
    <span class="comment">/**</span>
     <span class="comment">* {{models.target}} Relationship</span>
     <span class="comment">*</span>
     <span class="comment">*</span> <span class="keyword">@return</span> <span class="identifier-class">HasManyThrough</span>
     <span class="comment">*</span>
     <span class="comment">*</span> <span class="keyword">@version</span> <span class="comment">1.0.0</span>
     <span class="comment">*/</span>
    <span class="keyword">public function</span> <span class="text-target">{{ targetTable }}</span>(): <span class="identifier-class">HasManyThrough</span>
    {
        <span class="keyword">return</span> <span class="identifier-class">$this</span>-><span class="identifier-method">hasManyThrough</span>(
            <span class="text-target">{{ models.target }}::class</span>,
            <span class="text-intermediate">{{ models.intermediate }}::class</span>,
            '<span class="text-intermediate">{{ intermediateCurrentForeignKey }}</span>', <span class="comment">// Foreign key on {{ intermediateTable }} table...</span>
            '<span class="text-target">{{ targetIntermediateForeignKey }}</span>', <span class="comment">// Foreign key on {{ targetTable }} table...</span> 
            '<span class="text-current">id</span>', <span class="comment">// Local key on {{ currentTable }} table...</span> 
            '<span class="text-intermediate">id</span>' <span class="comment">// Local key on {{ intermediateTable }} table...</span> 
        );
    }
}</pre>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    props: {
      models: {
        type: Object,
        default: () => {
          return {};
        },
      },

      currentTable: {
        type: String,
        default: () => {
          return "";
        },
      },

      intermediateTable: {
        type: String,
        default: () => {
          return "";
        },
      },

      targetTable: {
        type: String,
        default: () => {
          return "";
        },
      },

      targetIntermediateForeignKey: {
        type: String,
        default: () => {
          return "";
        },
      },

      intermediateCurrentForeignKey: {
        type: String,
        default: () => {
          return "";
        },
      },
    },
  };
</script>

<style>
</style>