import Vue from 'vue';

Vue.filter('toSlug', function (value) {
  if (!value) return '';

  var slug = '';

  slug = value.toLowerCase()
    .replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
    .replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
    .replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
    .replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
    .replace(/đ/gi, 'd')
    .replace(/\s*$/g, '')
    .replace(/\s+/g, '-');

  return slug;
});

Vue.filter('wrapArray', function (value) {
  return [value];
});


Vue.filter('formatNumber', function (value, decimals = null, thousandSeperators = false) {
  if (decimals !== null) {
    value = parseFloat(value).toFixed(decimals);
  }

  value = value.toString().replace('.', ',');

  if (thousandSeperators) {
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  return value;
});