/**
 * Imports
 */
require('./filters/index')

import Vue from 'vue'

import '@/plugins/bootstrap-vue'

import App from '@/App'
import router from '@/router'
import VuePluralize from 'vue-pluralize'

/**
 * Basic settings
 */
Vue.config.productionTip = false
Vue.use(VuePluralize)

/**
 * Custom Middleware
 */
router.beforeEach((to, from, next) => {
  document.title = 'Relationship cheatsheet'

  var classList = document.body.classList;
  while (classList.length > 0) {
    classList.remove(classList.item(0));
  }

  classList.add(to.name)

  next();
});

/**
 * Initialize Vue
 */
new Vue({
  router,

  render: h => h(App)
}).$mount('#app');
